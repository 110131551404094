$white: #fff;
$white_2: #f3f3f3;
$white_3: #f7f7f7;
$semi_white: #f1f1f1;
$black: #222222;
$hover: #fd5c2c;
$hover_2: #fd7b2c;
$semi_hover: #001659;
$semi_hover_2: #ff8b45;
$semi_hover_3: #f4772a;
$semi_hover_4: #0d2e62;
$semi_hover_5: #002258;
$dark: #ddd;
$dark_2: #dfdfdf;
$dark_3: #cccccc;
$dark_4: #92a4d9;
$dark_5: #dbdbdb;
$dark_6: #dee2e6;
$dark_7: #dcdcdc;
$dark_8: #d9d9d9;
$dark_9: #d5d5d5;
$secondary_color: #7551ec;
$default_color: #656565;
$fb_link_bg: #3b5999;
$twitter_link_bg: #55acee;
$pinterest_linbk_bg: #bd081c;
$default_size: 16px;
$default_font: 'Poppins', sans-serif;  
$font_2: 'Rubik', sans-serif;
$font_3: 'Oswald', sans-serif;
$btn_padding: 10px 25px;
$bold_weight: 600;